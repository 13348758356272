.table {
  background: white;
}

.table th {
  color: #bdc4d0;
}

.table th, .table td {
  padding: 15px 20px;
}

.table.striped tbody tr:nth-child(odd) {
  background: #f8f8fb;
}

.table.hoverable td {
  cursor: pointer;
}

.hoverRow {
  position: absolute;
  box-shadow: 0 2px 8px rgba(8, 15, 22, .1);
  pointer-events: none;
  cursor: pointer;
  border-style: solid;
  border-width: 0 3px;
  left: -3px;
  right: -3px;
}
